.section_container {
  background-color: rgb(248, 248, 248);
  width: 100%;
  justify-content: center;
  display: flex;
}

.section_page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /*background-color: #F8F8F8;*/
  flex-direction: column;
}

.section_page_phones {
  display: flex;
  /*background-color: #F8F8F8;*/
  justify-content: space-around;
  height: fit-content;
  width: 100%;
  padding: 100px 10px 0px 10px;
  max-width: 1420px;
  flex-wrap: wrap;
}

.section_page_phone_block {
  display: flex;
  flex-direction: column;
  background-color: rgb(248, 248, 248);
  height: fit-content;
  /*max-width: 600px;*/
  /*width: 100%;*/
  padding: 70px 85px 0px 85px;
  border-radius: 20px;
  margin-top: 20px;
  /*flex-wrap: wrap;*/
}

.section_container img{
  width: auto;
  max-width: 90%;
}