/*.header{*/
/*  box-sizing: border-box;*/

/*  !* Auto layout *!*/

/*  display: flex;*/
/*  flex-direction: row;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  padding: 20px 340px;*/
/*  gap: 879px;*/

/*  !*position: absolute;*!*/
/*  width: 1920px;*/
/*  height: 64px;*/
/*  left: calc(50% - 1920px/2);*/
/*  top: 0px;*/

/*  !* white *!*/

/*  background: #F8F8F8;*/
/*  !* gray button *!*/

/*  border-bottom: 1px solid #999999;*/
/*}*/

.header{
  background: #f0f0f0;
  box-shadow: 0 2px 5px #ccc;
  width: 100%;
  position: fixed;
  top: 0;
  max-height: 64px;
  z-index: 11;
}

.header_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  z-index: 11;
}