.footer {
    border-top: 2px solid #ebebeb;
    padding: 20px;
    background-color: rgb(248, 248, 248);
}

.footer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
}

.footer-copyright {
    display: block;
    white-space: nowrap;
    color: #aaa;
    padding: 5px 0;
}

.footer-links {
    display: flex;
    flex-wrap: wrap;
}

.footer-link {
    display: block;
    margin-right: 30px;
    color: #000000;
    text-decoration: none;
    padding: 5px 0;
    cursor: pointer;
}

.terms {
    border-left: 1px solid #999999;
    border-right: 1px solid #999999;
    padding-left: 20px;
    padding-right: 20px;
}

@media screen and (max-width: 900px) {
    .footer-container {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 825px) {
    .footer-links {
        width: 100%;
        justify-content: center;
    }

    .footer-link {
        margin: 0 15px;
    }
}
