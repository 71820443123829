.homeContainer {
    background-color: rgb(248, 248, 248);
    width: 100%;
    justify-content: center;
    display: flex;
}

.home_page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /*background-color: #F8F8F8;*/
    flex-direction: column;
}

.home_page_phone_container {
    display: flex;
    /*background-color: #F8F8F8;*/
    justify-content: space-around;
    height: fit-content;
    width: 100%;
    padding: 130px 10px 60px 10px;
    max-width: 1420px;
    flex-wrap: wrap;
}

.home_page_phone_container div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    min-width: 350px;
}

.home_page_why_container {
    display: flex;
    height: fit-content;
    width: 100%;
    max-width: 1420px;
    flex-direction: column;
    padding: 180px 10px;
}

.home_page_why_container_block {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.container_column {
    display: flex;
    flex-direction: column;
    width: 295px;
}

.container_column p {
    margin-top: 3px;
    margin-bottom: 3px;
}

.vector_block {
    display: flex;
}

.homeContainer img{
   width: auto;
    max-width: 90%;
}