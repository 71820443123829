.App {
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.content {
    flex-grow: 1;
    /*display: flex;*/
    padding-top: 64px;
}

.learn_more {
    font-size: 20px;
    font-weight: 400;
    color: #F1633A;
}

.learn_more:hover {
  cursor: pointer;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.container {
    margin: 0 auto;
    max-width: 1420px;
    width: 100%;
    /*background-color: #F8F8F8;*/
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
