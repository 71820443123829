.contact_container {
    background-color: rgb(248, 248, 248);
    width: 100%;
    justify-content: center;
    display: flex;
    list-style: inside;
}

.contact_page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /*background-color: #F8F8F8;*/
    flex-direction: column;
}

.contact_page_block {
    display: flex;
    /*background-color: #F8F8F8;*/
    /*justify-content: space-around;*/
    height: fit-content;
    width: 100%;
    padding: 80px 10px;
    max-width: 1420px;
    flex-wrap: wrap;
}

.contact_page p{
    text-align: left;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 30px;
}
.contact_page ul{
    text-align: left;
}

.contact_page li{
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
}

.contact_container img{
    width: auto;
    max-width: 90%;
}